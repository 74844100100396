/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
//import Card from "@mui/material/Card";
//import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "promessa/components/DefaultNavbar";
import DefaultFooter from "promessa/components/DefaultFooter";

// Coworking page sections
//import Information from "pages/LandingPages/Coworking/sections/Information";
//import Testimonials from "pages/LandingPages/Coworking/sections/Testimonials";
//import AboutUs from "pages/LandingPages/Coworking/sections/AboutUs";
//import Places from "pages/LandingPages/Coworking/sections/Places";

// Routes
import routes from "promessa/routes";
import footerRoutes from "promessa/footer.routes";

import promessaImg from "promessa/assets/images/icon_alone.png";

import tiraImg from "promessa/assets/images/adf.jpg";
// Images
//import bgImage from "promessa/assets/images/banner_home.jpg";
import { PropTypes } from "prop-types";

function Asesor() {
  return (
    <div style={{ width: "100%" }}>
      <DefaultNavbar routes={routes} sticky transparent light={false} />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/expertos_banners/banner-socios-01.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            md={7}
            justifyContent={{ xs: "center", md: "start" }}
            alignItems={{ xs: "left", md: "left" }}
            sx={{
              textAlign: { xs: "left", md: "left" },
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <MKTypography
              variant="h1"
              color="#28558f"
              className="text-container"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Bienvenido
            </MKTypography>
            {/*            <MKTypography
              variant="h1_it"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Promessa
            </MKTypography>*/}
            <MKTypography variant="h3" color="#205599ff" pr={{ md: 12, lg: 24, xl: 0 }}>
              Soy [Nombre del Asesor]<br></br>tu asesor de seguros Promessa
            </MKTypography>
            {/*<MKTypography variant="h3" color="white" pr={{ md: 12, lg: 24, xl: 32 }}>
              Descubre nuestras opciones
            </MKTypography>
            <MKTypography variant="h3" color="white" pr={{ md: 12, lg: 24, xl: 32 }}>
              para tu bienestar
            </MKTypography> */}
            <MKBox sx={{ display: "flex", justifyContent: { xs: "center", md: "start" } }}>
              <MKButton
                variant="contained"
                color="warning"
                sx={{
                  paddingHorizontal: "16px",
                  paddingVertical: "32px",
                  width: "auto",
                  minWidth: "fit-content",
                  maxWidth: "fit-content",
                  flexShrink: 0,
                  color: "#4a2c00",
                }}
              >
                Cotiza
              </MKButton>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <MKBox
        style={{
          width: "100%",
          justifyContent: "space-evenly",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <iframe
          id="ytplayer"
          type="text/html"
          width="550"
          height="360"
          src="http://www.youtube.com/embed/M7lc1UVf-VE?autoplay=1&origin=http://example.com"
          frameBorder="0"
        />
        <MKBox
          style={{
            maxWidth: 550,
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <MKTypography variant="subtitle1">BIOGRAFIA</MKTypography>
          <MKTypography variant="h3">
            Comprometido con la seguridad financiera y el bienestar de mis clientes
          </MKTypography>
          <MKTypography variant="subtitle2" color="#eee" sx={{ mt: 4 }}>
            Con [X] años de experiencia en el sector de seguros, me enorgullece ofrecer un servicio
            personalizado y confiable a cada uno de mis clientes. Mi objetivo es brindarte
            tranquilidad y la protección que necesitas para enfrentar cualquier situación
          </MKTypography>
        </MKBox>
      </MKBox>
      <MKBox
        style={{
          display: "flex",
          flex: 1,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
        pt={6}
        mt={6}
      >
        <MKTypography variant="h2" mt={2} mb={2}>
          Nuestra Promessa para tu seguridad
        </MKTypography>
        <MKBox
          style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "center" }}
        >
          <MKBox
            id="b1"
            style={{ display: "flex", flexDirection: "column", maxWidth: "20%", margin: 8 }}
          >
            <MKBox
              component="img"
              src={promessaImg}
              style={{ width: 50, height: 50, marginLeft: -16 }}
            />
            <MKTypography variant="subtitle1">Seguro de Gastos Médicos</MKTypography>
            <MKTypography variant="subtitle2">
              Cobertura integral para tratamientos y emergencias médicas
            </MKTypography>
          </MKBox>
          <MKBox
            id="b2"
            style={{ display: "flex", flexDirection: "column", maxWidth: "20%", margin: 8 }}
          >
            <MKBox
              component="img"
              src={promessaImg}
              style={{ width: 50, height: 50, marginLeft: -16 }}
            />
            <MKTypography variant="subtitle1">Seguro de Vida</MKTypography>
            <MKTypography variant="subtitle2">
              Garantiza el futuro de los que más quieres
            </MKTypography>
          </MKBox>
        </MKBox>
        <MKTypography variant="h2" mt={4} mb={2}>
          Preguntas frecuentes
        </MKTypography>
        <MKTypography variant="h4" color="#ddd" style={{ width: "80%" }}>
          Encuentra respuestas a las dudas más comunes sobre nuestros servicios y productos de
          seguros
        </MKTypography>
        <MKBox
          style={{
            width: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Collapsable
            pregunta="¿Qué tipos de seguros ofrecen en Promessa?"
            respuesta="Lorem Ipsum"
          />

          <Collapsable pregunta="¿Cómo puedo cotizar un seguro?" respuesta="Lorem Ipsum" />

          <Collapsable
            pregunta="¿Qué debo hacer en caso de un siniestro?"
            respuesta="Lorem Ipsum"
          />

          <Collapsable pregunta="¿Dónde están ubicadas sus oficinas?" respuesta="Lorem Ipsum" />

          <Collapsable
            pregunta="¿Cómo puedo contactar a un asesor de Promessa?"
            respuesta="Lorem Ipsum"
          />
        </MKBox>
      </MKBox>

      <MKBox
        style={{
          display: "flex",
          width: "100%",
          height: "200px",
          backgroundImage: "url(" + tiraImg + ")",
          backgroundSize: "cover",
          backgroundPosition: "top",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          paddingLeft: 200,
          paddingRight: 200,
        }}
      >
        <MKBox style={{ display: "flex", flexDirection: "column" }}>
          <MKTypography color="white" variant="h4">
            ¡Obtén tu protección ahora mismo!
          </MKTypography>
          <MKTypography color="white" variant="h5">
            Haz clic en el botón para obtner más información
          </MKTypography>
        </MKBox>

        <MKButton
          variant="contained"
          color="warning"
          size="large"
          sx={{
            paddingHorizontal: "16px",
            paddingVertical: "32px",
            width: "auto",
            minWidth: "fit-content",
            maxWidth: "fit-content",
            flexShrink: 0,
            color: "white",
          }}
        >
          Cotiza
        </MKButton>
      </MKBox>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </div>
  );
}

function Collapsable({ pregunta, respuesta }) {
  const [abierto, setAbierto] = useState(false);

  return (
    <div style={{ width: "100%" }}>
      <MKBox
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #444",
        }}
      >
        <MKTypography>{pregunta}</MKTypography>
        <MKTypography variant="h1" p={2} onClick={() => setAbierto(!abierto)}>
          {abierto ? "-" : "+"}
        </MKTypography>
      </MKBox>
      {abierto && (
        <MKBox
          style={{
            width: "80%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #444",
          }}
        >
          {respuesta}
        </MKBox>
      )}
    </div>
  );
}

Collapsable.propTypes = {
  pregunta: PropTypes.string.isRequired,
  respuesta: PropTypes.string.isRequired,
};
export default Asesor;
