import { useState } from "react";
import PropTypes from "prop-types";

// @mui material components
//import Container from "@mui/material/Container";
//import Grid from "@mui/material/Grid";
//import Autocomplete from "@mui/material/Autocomplete";

// Otis Kit PRO components
import MKBox from "components/MKBox";
//import MKInput from "components/MKInput";

import Buscador from "../components/Buscador";

// DUMMY DATA
import data from "promessa/assets/expertos_data.json";
import MKTypography from "components/MKTypography";
import { Link } from "react-router-dom";

export default function Expertos({ en }) {
  //const [expertos,setExpertos] = useState([]);
  const [pagina, setPagina] = useState(1);

  const itemsPerPage = 4;

  // Calculate the indices for the slice
  const indexOfLastItem = pagina * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Get the current items for the page
  const expertos = data.slice(indexOfFirstItem, indexOfLastItem);

  // Function to change the page
  const paginate = (pagina) => setPagina(pagina);

  return (
    <MKBox
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Buscador />

      <MKTypography variant="h3" sx={{ color: "#28558f", ml: 2, pl: 1, mb: 0, pb: 0 }}>
        Nuestros Expertos en {en?.stateName}
      </MKTypography>
      <MKTypography variant="subtitle1" sx={{ color: "#333333", ml: 2, pl: 1, mb: 10 }}>
        Estamos aquí para ayudarte a proteger lo que más te importa
      </MKTypography>

      <MKBox
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <MKBox
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
          }}
        >
          {expertos.map((item) => (
            <MKBox
              key={item.id}
              style={{
                display: "flex",
                flexDirection: "row",
                minWidth: "40%",
                maxWidth: "80%",
                p: 2,
                m: 4,
                gap: 10,
                alignItems: "flex-end",
              }}
            >
              <Link to={"/asesor/" + item.id}>
                <MKBox
                  component="img"
                  src={`${process.env.PUBLIC_URL}/assets/expertos/${item.image}`}
                  alt="image"
                  borderRadius="md"
                  maxWidth="150px"
                  maxHeight="150px"
                  width="100%"
                />
              </Link>
              <MKBox style={{ display: "flex", flexDirection: "column" }}>
                <MKTypography variant="h4" color="black">
                  {item.name}
                </MKTypography>
                <MKTypography variant="body1" color="#28558f">
                  {item.email}
                </MKTypography>
                <MKTypography variant="sutitle2" color="#33333">
                  {item.phone}
                </MKTypography>
                <MKTypography variant="sutitle2" color="black">
                  {item.city}
                </MKTypography>
              </MKBox>
            </MKBox>
          ))}
        </MKBox>
        <MKBox
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          {/* Render pagination buttons */}
          {Array.from({ length: Math.ceil(data.length / itemsPerPage) }, (_, i) => (
            <p
              key={i}
              onClick={() => paginate(i + 1)}
              style={{
                borderRadius: 100,
                backgroundColor: i + 1 == pagina ? "#28558f" : "transparent",
                color: i + 1 == pagina ? "white" : "#28558f",
                border: i + 1 == pagina ? "transparent" : "1px solid #ccc",
                padding: 10,
                margin: 10,
                width: 50,
                height: 50,
                textAlign: "center",
              }}
            >
              {i + 1}
            </p>
          ))}
        </MKBox>
      </MKBox>
    </MKBox>
  );
}

Expertos.propTypes = {
  en: PropTypes.shape({
    id: PropTypes.number.isRequired,
    stateName: PropTypes.string.isRequired,
  }).isRequired,
};

Expertos.defaultProps = {
  en: {
    id: 0,
    stateName: "",
  },
};
