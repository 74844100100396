// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
//import icon from "assets/theme/components/icon";
//import TwitterIcon from "@mui/icons-material/Twitter";
//import GitHubIcon from "@mui/icons-material/GitHub";
//import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "promessa/assets/images/promess logo blue.svg";

// Estados de la República
import estadosJson from "promessa/assets/estados_federales.json";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Regulador por la Comisión Nacional de Seguros y Fianzas",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
    },
    {
      icon: <InstagramIcon />,
    },
  ],
  /*  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/CreativeTim/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/creativetim",
    },
    {
      icon: <GitHubIcon />,
      link: "https://github.com/creativetimofficial",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
    },
  ], */
  menus: [
    {
      name: "Directorio",
      items: [
        /*         { name: "Chihuahua", href: "" },
        { name: "Tamaulipas", href: "" }, */
      ],
      itemsJson: estadosJson.data,
      itemKey: "stateName",
    },
    {
      name: "Servicios",
      items: [
        { name: "Seguro de daños", href: "" },
        { name: "Seguro de vida", href: "" },
        { name: "Seguro de auto", href: "" },
      ],
    },
    {
      name: "Legal",
      items: [
        { name: "Terminos y condiciones", href: "" },
        { name: "Aviso de privacidad", href: "" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      Copyright &copy; {date} Promessa by MentaPimienta Despacho Craetivo{" "}
      <MKTypography
        component="a"
        href="https://www.creative-tim.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Creative Tim
      </MKTypography>
      .
    </MKTypography>
  ),
};
