/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
//import Card from "@mui/material/Card";
//import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
//import MKButton from "components/MKButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "promessa/components/DefaultNavbar";
import DefaultFooter from "promessa/components/DefaultFooter";

// Coworking page sections
//import Information from "pages/LandingPages/Coworking/sections/Information";
//import Testimonials from "pages/LandingPages/Coworking/sections/Testimonials";
//import AboutUs from "pages/LandingPages/Coworking/sections/AboutUs";
//import Places from "pages/LandingPages/Coworking/sections/Places";

// Routes
import routes from "promessa/routes";
import footerRoutes from "promessa/footer.routes";

// Images
import bgImage from "promessa/assets/images/banner-2_azul.jpg";
import { Card } from "@mui/material";

// Estados de la República
//import estadosJson from "promessa/assets/estados_federales.json";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import estados from "promessa/assets/estados_federales.json";
import logo from "promessa/assets/images/icon.svg";
import MKButton from "components/MKButton";

import Expertos from "promessa/pages/OficinaDetalles/sections/Expertos";

function OficinaDetalles() {
  const [estado, setEstado] = useState(null);
  const [oficinas, setOficinas] = useState([]);
  const [counter, setCounter] = useState(0);
  const { id } = useParams();

  useEffect(() => {
    setEstado(estados.data.find((es) => es.id == id));
    agregarCuatroOficinas();
  }, []);

  function agregarCuatroOficinas() {
    const nuevoOficinas = [...oficinas];
    nuevoOficinas.push(nuevaOficina());
    nuevoOficinas.push(nuevaOficina());
    nuevoOficinas.push(nuevaOficina());
    nuevoOficinas.push(nuevaOficina());

    setOficinas(nuevoOficinas);
  }

  function nuevaOficina() {
    setCounter(counter + 1);
    return <MatrizCard key={counter} />;
  }

  return (
    <div style={{ width: "100%" }}>
      <DefaultNavbar routes={routes} sticky transparent light />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            md={7}
            justifyContent={{ xs: "start", md: "center" }}
            alignItems={{ xs: "center", md: "center" }}
            marginLeft={{ xs: 0, md: -50 }}
            sx={{
              textAlign: { xs: "left", md: "left" },
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <MKTypography
              variant="h1_it"
              color="white"
              className="text-container"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Promessa
            </MKTypography>
            <MKTypography variant="h3" color="white" sx={{ whiteSpace: "nowrap" }}>
              en {estado?.stateName}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 1),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          alignItems: "center",
        }}
      >
        <MKBox
          className="wrap-container"
          pt={6}
          px={1}
          mt={6}
          sx={{
            p: 4,
            mx: { xs: 1, lg: 1 },
            mb: 4,
            mt: -30,
            pt: 10,
            flexDirection: "row",
            flex: 1,
            flexWrap: "wrap",
          }}
        >
          {oficinas}
        </MKBox>
        <MKButton
          variant="contained"
          color="warning"
          size="large"
          sx={{
            paddingHorizontal: 4,
            paddingVertical: 1,
            width: "auto",
            minWidth: "fit-content",
            maxWidth: "fit-content",
            flexShrink: 0,
            color: "#4a2c00",
            fontSize: "0.7em",
            fontWeight: "bold",
          }}
          onClick={agregarCuatroOficinas}
        >
          ver más
        </MKButton>
        <div style={{ marginBottom: 100 }}></div>
      </Card>
      <Expertos en={estado} />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </div>
  );
}
function MatrizCard() {
  return (
    <Card
      className="wrap-item"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center", // Ensure space between the text and dropdown
        alignItems: "center", // Align items vertically centered
        backgroundColor: "#ebeced",
        flexWrap: "wrap",
        p: 4,
        m: 2,
        gap: 2,
        flex: "1 1 200px" /* Adjust the basis as needed */,
        maxWidth: "200px" /* Maximum width for each column */,
      }}
    >
      <MKBox
        component="img"
        sx={{
          height: 24,
          width: 24,
          mt: 2,
        }}
        alt="Promessa logo"
        src={logo}
      />
      <MKTypography variant="subtitle2">MATRIZ</MKTypography>
      <MKTypography variant="caption">
        Blvd. Antonio Ortiz Mena #1807, Col. Las Águilas, C.P: 31214, Chuihuahua, Chihuahua
      </MKTypography>
      <MKTypography variant="caption">Tel.(614) 423-5500</MKTypography>

      <MKBox
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          flex: 1,
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
        }}
      >
        <MKButton variant="contained" color="info" size="small">
          8:30 AM
        </MKButton>
        <MKButton variant="contained" color="info" size="small">
          8:30 PM
        </MKButton>
      </MKBox>
    </Card>
  );
}

export default OficinaDetalles;
