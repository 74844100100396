/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
//import Card from "@mui/material/Card";
//import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "promessa/components/DefaultNavbar";
import DefaultFooter from "promessa/components/DefaultFooter";

// Coworking page sections
//import Information from "pages/LandingPages/Coworking/sections/Information";
//import Testimonials from "pages/LandingPages/Coworking/sections/Testimonials";
//import AboutUs from "pages/LandingPages/Coworking/sections/AboutUs";
//import Places from "pages/LandingPages/Coworking/sections/Places";

// Routes
import routes from "promessa/routes";
import footerRoutes from "promessa/footer.routes";

// Images
import bgImage from "promessa/assets/images/banner-2_azul.jpg";
import { Card } from "@mui/material";

// Estados de la República
import estadosJson from "promessa/assets/estados_federales.json";
import Dropdown from "promessa/pages/Oficinas/components/Dropdown";

function Oficinas() {
  return (
    <div style={{ width: "100%" }}>
      <DefaultNavbar routes={routes} sticky transparent light />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            md={7}
            justifyContent={{ xs: "center", md: "start" }}
            alignItems={{ xs: "left", md: "left" }}
            sx={{
              textAlign: { xs: "left", md: "left" },
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <MKTypography
              variant="h1"
              color="white"
              className="text-container"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Oficinas
            </MKTypography>
            <MKTypography variant="h3" color="white" pr={{ md: 12, lg: 24, xl: 0 }}>
              ¿Necesitas asistencia?
            </MKTypography>
            <MKTypography variant="h3" color="white" pr={{ md: 12, lg: 24, xl: 32 }}>
              Visita nuestra oficina en tu ciudad.
            </MKTypography>

            <MKBox sx={{ display: "flex", justifyContent: { xs: "center", md: "start" } }}>
              <MKButton
                variant="contained"
                color="warning"
                size="large"
                sx={{
                  paddingHorizontal: 4,
                  paddingVertical: 1,
                  width: "auto",
                  minWidth: "fit-content",
                  maxWidth: "fit-content",
                  flexShrink: 0,
                  color: "#4a2c00",
                  fontSize: "0.7em",
                  fontWeight: "bold",
                }}
              >
                cotiza
              </MKButton>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 4,
          mx: { xs: 1, lg: 1 },
          mb: 4,
          mt: 2,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 1),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center", // Ensure space between the text and dropdown
            alignItems: "center", // Align items vertically centered
          }}
        >
          <MKBox
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center", // Center the text vertically within its container
            }}
          >
            <MKTypography variant="h3">Contacta a tu Asesor Promessa</MKTypography>
            <MKTypography variant="subtitle1" sx={{ mt: 2 }}>
              Encuentra a tu asesor en tu ciudad
            </MKTypography>
          </MKBox>

          <MKBox
            sx={{
              whiteSpace: "nowrap", // Prevent text from wrapping
              flexShrink: 0, // Prevent the dropdown from shrinking
            }}
          >
            <Dropdown data={estadosJson.data} itemKey="stateName" route="oficina-detalles" />
          </MKBox>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </div>
  );
}

export default Oficinas;
