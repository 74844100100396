//import { Navigate } from "react-router-dom";
import Asesor from "./pages/Asesor";
import PromessaHome from "./pages/Home";
import OficinaDetalles from "./pages/OficinaDetalles";
import Oficinas from "./pages/Oficinas";

const routes = [
  {
    name: "Inicio",
    route: "/inicio",
    component: <PromessaHome />,
    key: 1,
    inMenu: true,
  },
  {
    name: "Oficinas",
    route: "/oficinas",
    component: <Oficinas />,
    key: 2,
    inMenu: true,
  },
  {
    name: "Iniciar sesión",
    route: "/login",
    component: <Oficinas />,
    key: 3,
    inMenu: true,
  },
  {
    name: "OficinaDetalles",
    route: "/oficina-detalles/:id",
    component: <OficinaDetalles />,
    key: 4,
    inMenu: false,
  },
  {
    name: "Asesor",
    route: "/asesor/:id",
    component: <Asesor />,
    key: 5,
    inMenu: false,
  },
];

export default routes;
